import { Box, Typography } from "@mui/material";
import D7 from "../../assets/images/img28.png";
import { MainBtn } from "../../components/StyledComponents";
import { HomeTitle } from "./title";

const Careers = () => {
  return (
    <Box sx={{ py: 4, px: { md: 16, xs: 2, backgroundColor: "#1f1e1e" } }}>
      <HomeTitle white title="JOIN OUR TEAM" />

      <Box
        sx={{
          marginTop: "80px",
          pt: 4,
          pb: 6,
          px: { md: 10, xs: 1 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img src={D7} style={{ width: "80%" }} alt="" />
        <Box sx={{ borderTop: "2px solid #3EB49D", width: "15%" }} />
        <span>
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              fontWeight: 500,
              fontSize: { md: "2.5vw", xs: "4vw" },
            }}
          >
            We believe supporting personal development creates a positive
            culture and environment for everyone.
          </Typography>
        </span>
        <MainBtn
          sx={{
            width: "fit-content",
            fontSize: { md: 22, xs: 16 },
            fontWeight: 400,
          }}
        >
          Join our Team
        </MainBtn>
      </Box>
    </Box>
  );
};

export default Careers;
