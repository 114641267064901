// ** React imports
import React, { FC } from "react";

// ** MUI imports
import Box from "@mui/material/Box";
import { Fab, Fade, useScrollTrigger } from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// ** Images imports
import HeaderImg from "../../assets/images/header.png";

export const HomeHero: FC<any> = (props: Props) => {
  return (
    <>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <HeroImage />
    </>
  );
};

export const PageHero: FC<any> = ({ title }) => {
  return (
    <div>
      <Fab size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
      <HeroImageTwo title={title} />
    </div>
  );
};
const HeroImage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${HeaderImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        backgroundAttachment: "fixed",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          maxWidth: { md: "80%", xs: "100%" },
          padding: 4,
          backdropFilter: "blur(3px)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { md: "4rem", xs: "2.5rem" },
            fontWeight: "bold",
            marginBottom: 2,
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
            // backgroundSize: "100%",
            // backgroundImage: `url(${HeaderImg})`,
            // color: "rgb(207, 230, 233)",
            color: "white",
            // backgroundPosition: "center",
          }}
        >
          With Artificial Intelligence <br />
          we bring software to life <br />
        </Typography>
        {/* <Typography
          variant="h2"
          sx={{
            fontSize: { md: "2rem", xs: "1.5rem" },
            marginBottom: 1,
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
            // backgroundSize: "100%",
            // backgroundImage: `url(${HeaderImg})`,
            color: "rgb(207, 230, 233)",
            // backgroundPosition: "center",
          }}
        >
          We use Artificial intelligence to make life easier
        </Typography> */}
      </Box>
    </Box>
  );
};

const HeroImageTwo: FC<any> = ({ title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${HeaderImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: { md: "50vh", sm: "30vh" },
        pt: { md: 0, sm: "15%", xs: "20%" },
        pb: 2,
        transition: "all 1s ease-in-out",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          maxWidth: { md: "80%", xs: "100%" },
          padding: 4,
          backdropFilter: "blur(5px)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { md: "3rem", sm: "2rem", xs: "1rem" },
            fontWeight: "bold",
            color: "white",
          }}
        >
          {title}
          <br />
        </Typography>
      </Box>
    </Box>
  );
};
interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

// Back to top
function ScrollTop(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}
