import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/footer";

const NoBannerLayout = () => {
  return (
    <div style={{ marginTop: 70 }}>
      <Outlet />
      <Footer />
    </div>
  );
};

export default NoBannerLayout;
