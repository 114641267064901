// import "../../pages/landing.css";
import logo from "../../assets/viviaLogo/LogoViviaBlanc.png";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HeaderImg from "../../assets/images/footer.png";
import { Typography, InputBase, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MainBtn } from "../StyledComponents";

const Footer = () => {
  // ** Router
  // const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundImage: `url(${HeaderImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        color: "white",
        py: 4,
        px: { md: 16, xs: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            color: "white",
          }}
        >
          <img
            style={{ width: 150, maxWidth: "100%", marginBottom: 20 }}
            src={logo}
            alt="vivia"
          />
          <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
            B22 Innovation city 2nd floor sahloul,Sousse Tunisia
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
            +216 73 11 11 96
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
            contact@vivianet.com
          </Typography>
          <Box>
            <Link
              style={{ color: "white" }}
              to="https://twitter.com/Viviasolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                sx={{
                  bgcolor: "#ffffff28",
                  borderRadius: 2,
                  p: 1,
                  mr: 1,
                  pointer: "cursor",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#3eb49d",
                    transition: "all 0.5 ease-in-out",
                  },
                }}
              />
            </Link>
            <Link
              style={{ color: "white" }}
              to="https://www.facebook.com/profile.php?id=100087897114949"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                sx={{
                  bgcolor: "#ffffff28",
                  borderRadius: 2,
                  p: 1,
                  mr: 1,
                  pointer: "cursor",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#3eb49d",
                    transition: "all 0.5 ease-in-out",
                  },
                }}
              />
            </Link>
            <Link
              style={{ color: "white" }}
              to="https://www.instagram.com/vivia.network/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                sx={{
                  bgcolor: "#ffffff28",
                  borderRadius: 2,
                  p: 1,
                  mr: 1,
                  pointer: "cursor",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#3eb49d",
                    transition: "all 0.5 ease-in-out",
                  },
                }}
              />
            </Link>

            <Link
              style={{ color: "white" }}
              to="https://www.linkedin.com/company/vivia-clinical-net/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                sx={{
                  bgcolor: "#ffffff28",
                  borderRadius: 2,
                  p: 1,
                  mr: 1,
                  pointer: "cursor",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#3eb49d",
                    transition: "all 0.5 ease-in-out",
                  },
                }}
              />
            </Link>
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Poppins", fontWeight: 700 }}
          >
            Useful Links
          </Typography>
          <ul style={{ listStyle: "none", fontSize: 14 }}>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                Home
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/our-solutions"
                style={{ textDecoration: "none", color: "white" }}
              >
                Solutions
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/consulting"
                style={{ textDecoration: "none", color: "white" }}
              >
                Consulting
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/research"
                style={{ textDecoration: "none", color: "white" }}
              >
                Research
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/career"
                style={{ textDecoration: "none", color: "white" }}
              >
                Career
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/community"
                style={{ textDecoration: "none", color: "white" }}
              >
                Community
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/open-source"
                style={{ textDecoration: "none", color: "white" }}
              >
                Open Source
              </Link>
            </li>
          </ul>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Poppins", fontWeight: 700 }}
          >
            Useful Links
          </Typography>
          <ul style={{ listStyle: "none", fontSize: 14 }}>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link
                to="/open-source"
                style={{ textDecoration: "none", color: "white" }}
              >
                CRAWN
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link style={{ textDecoration: "none", color: "white" }}>
                IMDA
              </Link>
            </li>
            <li>
              <span style={{ fontSize: 22, color: "#3eb49d" }}>&#8250;</span>{" "}
              <Link style={{ textDecoration: "none", color: "white" }}>
                E-LEARNING
              </Link>
            </li>
          </ul>
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Poppins", fontWeight: 700 }}
          >
            Newsletter
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: 16 }}
          >
            Subscribe to receive our latest updates and news
          </Typography>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              width: 300,
              maxWidth: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Your email address "
              inputProps={{ "aria-label": "Your email address" }}
            />

            <MainBtn
              sx={{
                borderRadius: "0px 10px 10px 0px",
                width: 100,
                maxWidth: "100%",
              }}
            >
              Subscribe
            </MainBtn>
          </Paper>
        </Box>
      </Box>
      <p style={{ textAlign: "center", marginTop: 40, fontWeight: 700 }}>
        Copyright For VIVIA, All rights are reserved 2023, Website made by
        VIVIANET.COM
      </p>
    </Box>
  );
};

export default Footer;
