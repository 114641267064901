import { SEND_MSG, SEND_MSG_FAILED } from "../actionTypes/researchTypes";

//interface Researchs Initial State
interface IContact {
  contact: {};
  loading: boolean;
  error: string | null;
}

const initialState: IContact = {
  contact: {},
  loading: false,
  error: null,
};

interface IAction {
  type: string;
  payload: any;
}
//Product reducer
export default function contactReducer(
  state = initialState,
  action: IAction
): IContact {
  const { type, payload } = action;
  switch (type) {
    case SEND_MSG:
      return {
        ...state,
        loading: true,
      };
    case SEND_MSG_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.msg,
      };

    default:
      return state;
  }
}
