import imgArtcile from "../assets/articles/article1.jpeg";
import imgArtcile2 from "../assets/articles/article2.jpeg";
import imgArtcile3 from "../assets/articles/article3.png";
import imgArticle21 from "../assets/articles/imgArticle2.1.jpeg";
import imgArticle2 from "../assets/articles/imgArticle2.jpeg";

export interface ISolutionProps {
  title: string;
  author: string;
  images?: any;
  description: string;
  id: string;
  linkedInLink: string;
  category: string;
  createdAt: string;
  updatedAt: string;
}

const article1: ISolutionProps = {
  title:
    "Parking in the Age of AI: How Technology is Revolutionizing the On-Street and Off-Street Parking Business",
  author: "Lotfi Ammar",
  id: "1",
  description: `As cities continue to grow, the demand for parking spaces continues to rise. Parking on streets and off-street lots has become a significant business, with the need for efficient parking solutions being a top priority. In recent years, the introduction of AI technology has brought forth new possibilities for managing parking businesses more efficiently. In this article, we will explore the ways in which AI is poised to change the parking on-street and off-street business in the next few months and years.
    AI-Powered Parking Management
    AI technology is set to transform how parking businesses manage their lots, especially those with off-street parking. By using machine learning algorithms, parking businesses can gather data about parking usage, including how long people park and which parking spaces are the most popular. The data can then be analyzed to develop more efficient parking management strategies that can help to optimize parking space usage.
    Real-Time Parking Availability
    Finding parking on crowded streets can be a frustrating experience. With the help of AI technology, drivers can quickly find available parking spaces in real time. AI-powered parking apps can use data from sensors installed in parking lots to display available parking spaces to drivers in real time. This technology not only makes finding a parking spot easier, but also reduces traffic congestion on busy streets.
    Predictive Maintenance
    Off-street parking lots are subject to wear and tear due to constant usage. Predictive maintenance powered by AI can help parking lot operators to detect issues early on, preventing breakdowns and reducing downtime. Machine learning algorithms can analyze data from sensors and detect patterns that indicate potential issues. This technology can help parking businesses to reduce maintenance costs and ensure that their lots are always in good condition.
    Improved Revenue Management
    AI technology can help parking businesses to optimize their revenue streams. Machine learning algorithms can analyze data on parking patterns to identify pricing trends that help to maximize revenue. For example, AI-powered systems can analyze data to determine the best pricing strategy for parking during busy hours, such as peak commuting times. By using this technology, parking businesses can maximize their revenue and make better use of their parking spaces.
    `,
  linkedInLink:
    "https://www.linkedin.com/pulse/parking-age-ai-how-technology-revolutionizing-on-street%3FtrackingId=yEmxeMSRQ2mpSZJFdHeu%252BA%253D%253D/?trackingId=yEmxeMSRQ2mpSZJFdHeu%2BA%3D%3D",
  images: [imgArtcile],
  category: "Industry",
  createdAt: "02-03-2021",
  updatedAt: "02-03-2021",
};
const article2: ISolutionProps = {
  title: "General AI & Superintelligence: When Machines will outsmart Humans?",
  author: "Lotfi Ben Romdhane",
  id: "2",
  linkedInLink:
    "https://www.linkedin.com/pulse/general-ai-superintelligence-when-machines-outsmart%3FtrackingId=tf9D8odsT5%252BoRI1Z9K92PQ%253D%253D/?trackingId=tf9D8odsT5%2BoRI1Z9K92PQ%3D%3D",
  images: [imgArtcile2, imgArticle2, imgArticle21],
  category: "Industry",
  description: `General Artificial Intelligence
    General Artificial Intelligence is a hot topic of debate in the industry and an active research area in the labs. It refers to a category of AI systems that can perform any intellectual task that Humans can perform, with the same level of flexibility and adaptability. Stated otherwise, General AI systems will perform as well as Humans independently of the task domain like speech recognition, solving quizzes, and walking. While significant progress has been made in AI research in recent years, including areas such as natural language processing, computer vision, and game playing, the development of true General AI remains a challenging and complex task. In fact, nowadays-advanced AI systems like ChatGPT, and Google Assistant specialize in specific tasks; but they are still far away from General AI systems.
    Some experts in the field believe that General AI could be developed within the next few decades, while others believe that it may be many years or even decades before we achieve this goal. The timeline for the development of General AI depends on various factors, including advances in hardware and software technology, the most important breakthroughs in AI algorithms and architectures, and improvements in our understanding of human intelligence.
    Superintelligence
    Superintelligence is an AI system that surpasses human intelligence in all domains, including creativity, problem-solving, and strategic thinking. We have witnessed some facts in the last period, especially in creativity. For example, in 2022, an AI-generated painting won the prize of the Colorado State Fair’s annual art competition (see Figure below).
    The main idea of superintelligence is that once AI systems reach a certain level of intelligence, they may be capable of rapidly improving their own capabilities, leading to an intelligence explosion that far surpasses human intelligence. It is obvious that reaching superintelligence requires General AI systems that are domain-independent. Also, we should note that a major factor that accelerates the development of super-intelligent AI systems is the exponential growth of computing. As predicted by computer scientists Ray Kurzweil, the computational power of today’s machines is almost that of the Human brain. However, soon it will by far surpass it, opening the era of superintelligence (see Figure below).
    What about Ethics?
    While the development of super intelligent AI is still speculative and uncertain, it is a topic of active discussion among AI researchers and ethicists, who are grappling with the potential implications of such a development. Some experts have raised concerns about the possibility of super-intelligent AI posing an existential threat to humanity if its goals and values are not aligned with ours. Others argue that the development of super-intelligent AI could have transformative benefits for society, such as accelerating scientific discovery, improving healthcare outcomes, and addressing global challenges such as climate change.
Finally, whether we argue for or against super-intelligent AI systems, we already witness their appearance. We, as Human beings, need to adapt rapidly to this new situation. At least, we are still much superior to AI systems in this adaptation quality.
`,
  createdAt: "April 17, 2023",
  updatedAt: "April 17, 2023",
};
const article3: ISolutionProps = {
  title:
    "ChatGPT, Google Bard, Bing AI, and beyond: could machines be emotionally intelligent?",
  author: "Lotfi Ben Romdhane",
  id: "3",
  linkedInLink:
    "https://www.linkedin.com/pulse/chatgpt-google-bard-bing-ai-beyond-could-machines-emotionally%3FtrackingId=SYo6UKFySLSAqLishW5qVQ%253D%253D/?trackingId=SYo6UKFySLSAqLishW5qVQ%3D%3D",
  images: [imgArtcile3],
  category: "Industry",
  description: `Emotional Intelligence?
        Emotional intelligence refers to our ability as Humans to recognize, understand and manage our emotions. The concept of emotional intelligence (popularized by the psychologist Daniel Goleman in his 1995 book "Emotional Intelligence") is based on the idea that emotional skills are just as important as cognitive skills for success in life, both personally and professionally. Individuals with high emotional intelligence are typically better able to manage stress, build positive relationships, and communicate effectively.
        Artificial Intelligence (AI)?

        Artificial Intelligence (AI) is simply the ability of machines to achieve/mimic human-like tasks that involve reasoning; and go beyond simple calculations such as language understanding, solving hard problems, playing chess, etc. From a computer science perspective, AI is a collection of data-driven non-conventional algorithms designed to solve these problems to our satisfaction. These include neural networks' machine learning algorithms, pattern recognition algorithms, etc.
Nowadays, AI has reached a good level of maturity mainly due to the exponential growth of computing, which eases the execution even of complex models that are generally computationally demanding. Around the year 2025, we will have an AI that has almost the capability of the human brain. This is becoming apparent in one of the main difficult tasks of human language understanding.
Natural language understanding (NLU) in the new era: ChatGPT, Google Bard, and Bing AI
Natural language understanding (NLU) is one of the most complex tasks done “easily” by humans, and it is a sign of human super-intelligence. NLU enables computers to understand commands without the formalized syntax of computer languages. NLU also enables computers to communicate back to humans in their own languages.
The emergence of robust machine learning models such as Long Short Term Memory (LSTM) and graph neural networks (GNN) brought NLU to a level that is not so far from the human level. This helped computer scientists to design AI systems that process and understand natural language input from users, and generate appropriate responses based on that understanding. Therefore, we have witnessed in this period the appearance of robust systems that can communicate in a human-like performance as ChatGPT, Google Bard, and Bing AI.
ChatGPT is based on a specific neural network architecture called a transformer. This transformer architecture uses self-attention mechanisms that allow the model to focus on different parts of the input sequence during each step of processing, without relying on any external alignment information. Google Bard is based on Google’s learning model "LaMDA" (Language Model for Dialogue Applications). LaMDA is based on transformer architecture, similar to the architecture used in OpenAI's GPT models. However, LaMDA is specifically designed to improve conversational AI by allowing the model to better understand the context and generate more natural responses. Regarding Bing AI developed by Microsoft, it is based on a variety of learning models depending on the specific task or application. For example, Microsoft uses deep learning models such as convolutional neural networks (CNNs) and recurrent neural networks (RNNs) to improve the accuracy and relevance of search results in the Bing search engine.
However, humans express many emotions when dealing with each other including conversations. These emotions are expressed in several ways and are even context-dependent in several cases. Hence, in the next decade, our major concern as computer scientists is to add “feelings” and “emotion” to these models so that they can reach the human level. This leads us to talk about Emotional Artificial Intelligence, which is expected to reach its maturity level in the era of super-intelligence expected to come in the year 2050.
Emotional AI?
Nowadays, machines can recognize and respond to emotional cues such as facial expressions and tone of voice. For example, we can detect the fatigue of the driver using his facial expressions and thereby give full control to the driving system to prevent car accidents. Therefore, we can assert that nowadays AI can recognize these emotional cues. However, they cannot still truly understand and experience emotions in the same way we humans do. One of the challenges of developing emotional AI is that emotions are not simply a matter of input and output. They are a dynamic and fluid process involving ongoing interaction between the individual, the environment, and others. This means that machines would need to be able to adapt and learn from experience to truly develop emotional intelligence.
However, there is ongoing research in the area of emotional AI, which focuses on developing machines that can recognize, interpret, and respond to human emotions in a more nuanced and sophisticated way. For example, researchers are exploring how machine-learning algorithms can be trained to recognize more subtle emotional cues, such as changes in body language and micro-expressions. Some AI systems were designed to mimic emotional responses, such as happiness or sadness, to make them seem more human-like. These systems are typically used in areas such as customer service and healthcare, where it is important to provide a more personalized and empathetic experience. Another challenge is that emotions are closely tied to human values and ethics. For example, the ability to feel empathy and compassion is an important aspect of human emotional experience, and it is closely linked to ethical decision-making. As machines become more advanced and integrated into our daily lives, it will be important to ensure that they are designed and programmed in a way that reflects human values and ethical principles.
What is next?
This intelligence explosion will significantly impact human civilization. These computer programs and AI will turn into super-intelligent machines with cognitive capacity beyond human capabilities. Social networks software  (Social Bots) will gather data from social networks about humans and will use sophisticated machine learning models to influence their consuming habits, and their way of thinking, and will even create for them virtual friends that match their emotional needs. Within three or four decades, we will reach the AI singularity involving advanced systems that are connected to our bodies (and even every part of our bodies) and to our environment gathering, reasoning, and suggesting (and even sometimes deciding instead of humans) in real-time. The barriers between humans and machines (i.e., the virtual world) will disappear. Thanks to nanotechnology and IoT devices, these systems will become so advanced that AI transcends human intelligence, potentially erasing the boundary between humanity and computers.
However, there are also important ethical considerations to take into account, such as the potential for machines to be used to manipulate or exploit human emotions. As AI technology continues to evolve, it will be important to ensure that it is developed in a way that is aligned with human values and ethical principles.
References
1.     Picard, R. W. (2000). Affective computing. MIT Press.
2.     Turkle, S. (2011). Alone together: Why we expect more from technology and less from each other. Basic Books.
3.     Calvo, R. A., & Peters, D. (2014). Promoting psychological well-being with mobile apps. IEEE Pervasive Computing, 13(3), 10-13.
4.     Charland, A., & Leroux, B. (2011). Using social psychology to motivate contributions to online communities. International Journal of Human-Computer Studies, 69(12), 841-853.
5.     Kappas, A. (2011). Emotion and computing: Perspectives from cognitive theories. Mind & Society, 10(1), 39-56.
6.    Kim, K., Bang, J. W., & Kim, S. (2017). Emotion recognition using a hierarchical binary pattern tree from facial expression and its dynamic features. IEEE Transactions on Affective Computing, 8(2), 171-183.
7.    McDuff, D., El Kaliouby, R., & Picard, R. W. (2015). Crowdsourcing facial responses to online videos: Developing a face-video benchmark for emotion recognition. Proceedings of the 2015 ACM International Conference on Multimodal Interaction, 435-442.
8.    Zhao, X., Peng, Y., & Tian, Y. (2018). Emotion recognition from speech: Tools and challenges. IEEE/ACM Transactions on Audio, Speech, and Language Processing, 26(5), 900-913.
    `,
  createdAt: "02-03-2021",
  updatedAt: "02-03-2021",
};

const newsletter: ISolutionProps[] = [article1, article2, article3];

export function getNewsletterbyId(id: string): ISolutionProps[] {
  return newsletter.filter((news) => news.id === id);
}

export function getCategoryCounts(): {
  categoryName: string;
  newsletterNumber: number;
}[] {
  const categoryCounts: { [category: string]: number } = {};

  newsletter.forEach((news) => {
    const { category } = news;
    categoryCounts[category] = (categoryCounts[category] || 0) + 1;
  });

  return Object.entries(categoryCounts).map(
    ([categoryName, newsletterNumber]) => ({
      categoryName,
      newsletterNumber,
    })
  );
}

export function filterNewsletterByAllCategories(
  categories: string[]
): { categoryName: string; newsletterNumber: number }[] {
  const categoryMap: { [category: string]: number } = {};

  for (const news of newsletter) {
    if (categories.includes(news.category)) {
      if (!categoryMap[news.category]) {
        categoryMap[news.category] = 0;
      }
      categoryMap[news.category]++;
    }
  }

  return categories.map((category) => ({
    categoryName: category,
    newsletterNumber: categoryMap[category] || 0,
  }));
}
export function filterNewsletterById(id?: string): any {
  const solution = newsletter.find((sol) => sol.id === id);
  const index = id ? newsletter.findIndex((s) => s.id === id) : -1;

  if (solution) {
    const before = index > 0 ? newsletter[index - 1] : null;
    const after = index < newsletter.length - 1 ? newsletter[index + 1] : null;
    return [
      before ? { id: before.id, title: before.title } : null,
      solution,
      after ? { id: after.id, title: after.title } : null,
    ];
  } else {
    return [null, null, null];
  }
}
export default newsletter;
