import { FC } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

// ** Images imports
import HeaderImg from "../assets/images/header.png";
import Footer from "../components/footer/footer";
import Navbar from "../components/Navbar/navbar";

// ----------------------------------------------------------------------

const HeroImageTwo: FC<any> = () => {
  let title;
  if (window.location.pathname.includes("/our-solutions")) {
    title = "Our Solutions";
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${HeaderImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: { md: "50vh", sm: "30vh" },
        pt: { md: 0, sm: "15%", xs: "20%" },
        pb: 2,
        transition: "all 1s ease-in-out",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          maxWidth: { md: "80%", xs: "100%" },
          padding: 4,
          backdropFilter: "blur(5px)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { md: "3rem", sm: "2rem", xs: "1rem" },
            fontWeight: "bold",
            color: "white",
          }}
        >
          {title}
          <br />
        </Typography>
      </Box>
    </Box>
  );
};

// ----------------------------------------------------------------------

export const SimpleLayout: FC<any> = ({ title }) => {
  return (
    <div>
      <HeroImageTwo />
      <Box sx={{ my: 10 }}>
        <Outlet />
      </Box>
      <Footer />
    </div>
  );
};
