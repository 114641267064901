import {
  GET_RESEARCHS,
  GET_RESEARCH,
  RESEARCH_LOADING,
  RESEARCH_FAILED,
} from "../actionTypes/researchTypes";

//interface Researchs Initial State
interface IResearchsInState {
  researchs: [];
  research: {};
  loading: boolean;
  error: string | null;
}

const initialState: IResearchsInState = {
  researchs: [],
  research: {},
  loading: false,
  error: null,
};

interface ResearchsAction {
  type: string;
  payload: any;
}
//Product reducer
export default function researchReducer(
  state = initialState,
  action: ResearchsAction
): IResearchsInState {
  const { type, payload } = action;
  switch (type) {
    //Initial State
    case RESEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    //There is an ERROR
    case RESEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.msg,
        researchs: [],
      };
    //Get all Products
    case GET_RESEARCHS:
      return {
        ...state,
        researchs: payload.researchs,
        error: null,
        loading: false,
      };
    //Get Product By ID
    case GET_RESEARCH:
      return {
        ...state,
        research: payload.research,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
}
