// ** React imports
import React, { useState } from "react";

// * *Router imports
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, useScrollTrigger } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";

// ** Images imports
import logo from "../../assets/viviaLogo/LogoViviaBlanc.png";
import { SoonChip } from "../StyledComponents";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const Navbar = () => {
  // ** States
  const [colorChange, setColorchange] = useState(false);

  // ** Router
  const navigate = useNavigate();

  const changeNavbarColor = () => {
    if (window.scrollY >= 150) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  // ** Functions
  function ElevationScroll(props: Props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }} id="back-to-top-anchor">
      <ElevationScroll>
        <AppBar
          sx={{
            // bgcolor: colorChange ? "rgba(0, 31, 63)" : "transparent",
            bgcolor: "#00162be8",

            transition: "all 1s ease-in-out",
          }}
        >
          <Toolbar
            sx={{
              pt: colorChange ? 0 : 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                width: "20%",
                maxWidth: 150,
                maxHeight: 44,
                transition: "all 1s ease-in-out",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />

            <DropdownMenu />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
};

//  MENU
const DropdownMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const navigate = useNavigate();
  return (
    <Box>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
      >
        <MenuRoundedIcon
          style={{
            color: "white",
            fontSize: "40px",
            transform: open ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.5s ease",
          }}
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper style={{ padding: 15 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/consulting");
                    }}
                  >
                    Home{" "}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("our-solutions");
                    }}
                  >
                    Solutions
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/consulting");
                    }}
                  >
                    Consulting
                    <SoonChip label="Soon" size="small" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/researchs");
                    }}
                  >
                    Research <SoonChip label="Soon" size="small" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/careers");
                    }}
                  >
                    Careers <SoonChip label="Soon" size="small" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/newsletter");
                    }}
                  >
                    Newletter
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/open-source");
                    }}
                  >
                    Open Source <SoonChip label="Soon" size="small" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/team");
                    }}
                  >
                    Team
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/contact");
                    }}
                  >
                    Contact <SoonChip label="Soon" size="small" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default Navbar;
