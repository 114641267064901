import industryIcon from "../assets/icons/v_custom.svg";
export interface ISolutionProps {
  title: string;
  icon: any;
  category: string;
  images?: string;
  deliveryDate: string;
  description: string;
  id: string;
  version: string;
  client: string;
}

const aladin: ISolutionProps = {
  title: "Aladin",
  icon: industryIcon,
  category: "industry",
  deliveryDate: "Juin 2023  ",
  id: "1",
  version: "v1.0",
  client: "EFFIA BELGIQUE",
  description:
    "The ALADIN platform, also known as Aladdin Mobility Management, simplifies parking management for administrators with its comprehensive solution for managing main and sub-parking areas. Its API-based and user-friendly interface allows real-time monitoring of parking space availability and occupancy rates, as well as customized pricing structures for different users and scenarios. ALADIN also offers features for managing car rentals, electric vehicle charging, and parking spot reservations, as well as detailed analytics and reporting on parking usage.  ",
};
const jafar: ISolutionProps = {
  title: "Jafar",
  icon: industryIcon,
  category: "industry",
  deliveryDate: "Juin 2023  ",
  id: "2",
  version: "v1.0",
  client: "EFFIA BELGIQUE",
  description:
    "JAFAR is a platform specifically designed for managing IoT components in parking lots, with functionalities for managing user credentials and IoT materials. Administrators can easily add, edit, or remove materials as needed using its material management feature. ",
};
const sultan: ISolutionProps = {
  title: "Sultan",
  icon: industryIcon,
  category: "industry",
  deliveryDate: "Juin 2023  ",
  id: "3",
  version: "v1.0",
  client: "EFFIA BELGIQUE",
  description:
    "Sultan is a parking reservation and subscription web service that provides customers with an easy-to-use platform for finding and reserving parking spots at designated facilities. Its additional features, including real-time monitoring of parking availability, automated payment processing, and mobile app integration, streamline the parking experience for customers and facility operators. Sultan also offers consulting services to help businesses optimize their parking operations and improve revenue streams.",
};
const jasmin: ISolutionProps = {
  title: "Jasmin",
  icon: industryIcon,
  category: "industry",
  deliveryDate: "Juin 2023  ",
  id: "4",
  version: "v1.0",
  client: "EFFIA BELGIQUE",
  description:
    "Jasmin is a CRM platform tailored for parking management that enables operators to manage client complaints and supplier relationships efficiently. With its user-friendly interface, operators can track the status of each complaint and supplier relationship and access valuable insights into the performance of their parking operations and supplier relationships. Jasmin is a crucial tool for optimizing parking management operations, improving the customer experience, and strengthening supplier relationships. ",
};

const solutions: ISolutionProps[] = [aladin, jafar, sultan, jasmin];

export function filterSolutionsByCategory(category: string): ISolutionProps[] {
  return solutions.filter((solution) => solution.category === category);
}

export function filterSolutionsById(id?: string): any {
  const solution = solutions.find((sol) => sol.id === id);
  const index = id ? solutions.findIndex((s) => s.id === id) : -1;
  const before = index > 0 ? solutions[index - 1].id : null;
  const after = index < solutions.length - 1 ? solutions[index + 1].id : null;

  return [before, solution, after];
}

export function filterSolutionsByAllCategories(
  categories: string[]
): { category: string; solutions: ISolutionProps[] }[] {
  const solutionsByCategory: { [category: string]: ISolutionProps[] } = {};

  // Select categories in the solutions
  for (const solution of solutions) {
    if (categories.includes(solution.category)) {
      if (!solutionsByCategory[solution.category]) {
        solutionsByCategory[solution.category] = [];
      }
      solutionsByCategory[solution.category].push(solution);
    }
  }

  // Remove empty arrays ==> categories with no solutions
  const filteredCategories = Object.keys(solutionsByCategory).filter(
    (category) => solutionsByCategory[category].length > 0
  );

  // Map solutions to an array of objects with category and solutions properties
  const result = filteredCategories.map((category) => ({
    category,
    solutions: solutionsByCategory[category],
  }));

  return result;
}

export function filterDummyDataByCategories(): {
  category: string;
  solutionsList: ISolutionProps[];
}[] {
  const categories = Array.from(
    new Set(solutions.map((solution) => solution.category))
  );

  return categories.map((category) => {
    const solutionsList = solutions.filter(
      (solution) => solution.category === category
    );
    return { category, solutionsList };
  });
}
export default solutions;
