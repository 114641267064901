import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

// ** Images imports
import icon from "../../assets/icons/Diamond.svg";

// ** Functions imports
import {
  filterDummyDataByCategories,
  filterSolutionsByCategory,
} from "../../dummyData/solutionsData";
import { useNavigate } from "react-router-dom";

const SolutionsThree = () => {
  const category = window.location.hash.slice(1);

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        gap: 10,
        px: { md: 10, xs: 1 },
      }}
    >
      {category === "" ? <IndustryAllCategories /> : <IndustryCategory />}
    </Box>
  );
};

const IndustryCategory = () => {
  const category = window.location.hash.slice(1);
  const industrySolutions = filterSolutionsByCategory(category);
  const navigate = useNavigate();

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "90vw",
          maxWidth: "100%",
          marginBottom: 20,
        }}
      >
        <img src={icon} alt="" style={{ width: "19px", height: "29px" }} />
        <div style={{ borderTop: "2px solid #3EB49D", width: "90%" }}></div>
      </div>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: "inline-block",
            width: { md: "calc(33.33% - )", sm: "440px", xs: "100%" },
            maxWidth: "100%",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "30px",
              lineHeight: "50px",
              letterSpacing: 4,
              color: "#A7A7A7",
              width: { md: "334px", xs: "100%" },
              maxWidth: "100%",
            }}
          >
            DISCOVER MORE ABOUT
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              lineHeight: "50px",
              letterSpacing: 4,
              color: "#3EB49D",
              my: 2,
              textTransform: "uppercase",
            }}
            variant="h2"
          >
            {category}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "26px",
              lineHeight: "50px",
              letterSpacing: 4,
              color: "#A7A7A7",
              fontStyle: "italic",
            }}
          >
            Click on the solution for more solutions
          </Typography>
        </Box>
        {industrySolutions?.map((project: any) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: { md: "350px", sm: "350px", xs: "100%" },
                bgcolor: "#F8F8F8",
                maxWidth: "100%",
                py: 4,
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                cursor: "pointer",
                transition: "all 0.5s ease-in-out",
                webkitMask:
                  "linear-gradient(#000 0 0), linear-gradient(#000 0 0)",
                WebkitMaskComposite: "xor",
                "&:hover": {
                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                  backgroundColor: "white",
                },
              }}
              onClick={() => navigate(`/our-solutions/solution/${project.id}`)}
            >
              <img
                src={project.icon}
                alt="VIVIA custom icon"
                width="70px"
                height="70px"
              />
              <Typography
                variant="body2"
                sx={{ color: "#555A69", fontSize: "32px", fontWeight: 500 }}
              >
                {project.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  // width: { md: "70%", xs: "90%" },
                  p: 2,
                  lineHeight: 2,
                }}
              >
                {project.description.slice(0, 95)} ...
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
const IndustryAllCategories = () => {
  const navigate = useNavigate();
  const solutionsByCategory = filterDummyDataByCategories();

  return (
    <>
      {solutionsByCategory.map((categrory, index) => (
        <Box key={index}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              width: "90vw",
              maxWidth: "100%",
              marginBottom: 20,
            }}
          >
            <img src={icon} alt="" style={{ width: "19px", height: "29px" }} />
            <div style={{ borderTop: "2px solid #3EB49D", width: "90%" }}></div>
          </div>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                width: { md: "calc(33.33% - )", sm: "440px", xs: "100%" },
                maxWidth: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "30px",
                  lineHeight: "50px",
                  letterSpacing: 4,
                  color: "#A7A7A7",
                  width: { md: "334px", xs: "100%" },
                  maxWidth: "100%",
                }}
              >
                DISCOVER MORE ABOUT
              </Typography>

              <Typography
                sx={{
                  fontWeight: 700,
                  lineHeight: "50px",
                  letterSpacing: 4,
                  color: "#3EB49D",
                  my: 2,
                  textTransform: "uppercase",
                }}
                variant="h2"
              >
                {categrory.category}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "26px",
                  lineHeight: "50px",
                  letterSpacing: 4,
                  color: "#A7A7A7",
                  fontStyle: "italic",
                }}
              >
                Click on the solution for more solutions
              </Typography>
            </Box>
            {categrory.solutionsList?.map((project: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: { md: "350px", sm: "350px", xs: "100%" },
                    bgcolor: "#F8F8F8",
                    maxWidth: "100%",
                    py: 4,
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "10px",
                    cursor: "pointer",
                    transition: "all 0.5s ease-in-out",
                    webkitMask:
                      "linear-gradient(#000 0 0), linear-gradient(#000 0 0)",
                    WebkitMaskComposite: "xor",
                    "&:hover": {
                      boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                      backgroundColor: "white",
                    },
                  }}
                  onClick={() =>
                    navigate(`/our-solutions/solution/${project.id}`)
                  }
                >
                  <img
                    src={project.icon}
                    alt="VIVIA custom icon"
                    width="70px"
                    height="70px"
                  />
                  <Typography
                    variant="body2"
                    sx={{ color: "#555A69", fontSize: "32px", fontWeight: 500 }}
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      // width: { md: "70%", xs: "90%" },
                      p: 2,
                      lineHeight: 2,
                    }}
                  >
                    {project.description.slice(0, 95)} ...
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
    </>
  );
};
export default SolutionsThree;
