// ** React imports
import React, { useState } from "react";

import { Alert, Box, Typography } from "@mui/material";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlined from "@mui/icons-material/PhoneAndroidOutlined";
import MailOutlined from "@mui/icons-material/MailOutlined";
import { BootstrapInput } from "../../components/StyledInputs/styledInputs";
import FormControl from "@mui/material/FormControl";
import { HomeTitle } from "./title";
import { MainLoadingBtn } from "../../components/StyledComponents";
import SendIcon from "@mui/icons-material/Send";
import { sendMsg } from "../../redux/actions/contact";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";

const Contact = () => {
  // ** States
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);
  const [data, setData] = useState({});

  // ** Hooks
  const dispatch = useDispatch<any>();

  // ** Functions

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(sendMsg(data))
      .then((res: any) => {
        setLoading(false);
        setSucess(true);

        setTimeout(() => {
          setSucess(false);
        }, 6000);
      })
      .catch((error: any) => {
        setTimeout(() => {
          setLoading(false);
        }, 6000);
      });
  };

  return (
    <Box sx={{ py: 4, px: { md: 16, xs: 2 } }}>
      <HomeTitle title="CONTACT US" />

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          gap: 4,
          mt: 4,
        }}
      >
        <Box
          sx={{
            width: { md: "40%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "4",
            height: 200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <LocationOnOutlined
              sx={{
                bgcolor: "#3EB49D",
                color: "white",
                p: 1,
                fontSize: 28,
                borderRadius: "10px",
              }}
            />
            <div>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Poppins", fontWeight: 600 }}
              >
                Location
              </Typography>
              <Typography variant="body2" sx={{ color: "#555A69" }}>
                B22 Innovation city 2nd floor sahloul,Sousse Tunisia
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <MailOutlined
              sx={{
                bgcolor: "#3EB49D",
                color: "white",
                p: 1,
                fontSize: 28,
                borderRadius: "10px",
              }}
            />
            <div>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Poppins", fontWeight: 600 }}
              >
                Email
              </Typography>
              <Typography variant="body2" sx={{ color: "#555A69" }}>
                contact@vivianet.com
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <PhoneAndroidOutlined
              sx={{
                bgcolor: "#3EB49D",
                color: "white",
                p: 1,
                fontSize: 28,
                borderRadius: "10px",
              }}
            />
            <div>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Poppins", fontWeight: 600 }}
              >
                Phone
              </Typography>
              <Typography variant="body2" sx={{ color: "#555A69" }}>
                +216 73 11 11 96
              </Typography>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            width: { md: "60%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 2,
            }}
          >
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <BootstrapInput
                placeholder="Your full name"
                id="bootstrap-input"
                type="text"
                name="senderFullName"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl variant="standard" sx={{ flex: 1 }}>
              <BootstrapInput
                placeholder="Your Email"
                id="bootstrap-input"
                type="email"
                name="senderEmail"
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <FormControl variant="standard">
            <BootstrapInput
              placeholder="Subject"
              id="bootstrap-input"
              type="text"
              name="messageSubject"
              onChange={handleChange}
            />
          </FormControl>
          <textarea
            style={{
              flex: 2,
              padding: 15,
              borderRadius: 5,
              borderColor: "#CED4DA",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontSize: "16px",
              maxWidth: "100%",
              backgroundColor: "#fcfcfb",
            }}
            onChange={handleChange}
            placeholder="..."
            id="outlined-basic"
            name="messageBody"
            cols={20}
            rows={5}
          ></textarea>
          <MainLoadingBtn
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            // sx={{ alignSelf: "end" }}
            sx={{ alignSelf: "center" }}
          >
            <span>Send</span>
          </MainLoadingBtn>
        </Box>
      </Box>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">Message sent successfuly !</Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
