import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Poppins';
            font-style: normal;
          }`,
    },
  },
  palette: {
    primary: {
      main: "#3EB49D",
    },
  },
});
