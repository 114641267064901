import D1 from "../../assets/images/card1.png";
import D2 from "../../assets/images/card2.png";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Box } from "@mui/material";

// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import { CardThree } from "../../components/customCards/customCards";
import { HomeTitle } from "./title";
import newsletter from "../../dummyData/newlsetterData";

const Newsletter = () => {
  // ** Caroussel options
  const options = {
    type: "loop",
    gap: "1rem",
    autoplay: true,
    pauseOnHover: false,
    arrows: false,
    wheel: true,
    pagination: true,
  };

  // **
  const newsltterList = newsletter;
  console.log(newsltterList);
  return (
    <Box sx={{ py: 4, px: { md: 16, xs: 2 } }}>
      <HomeTitle title="Newsletter" />
      <Splide options={options} aria-label="My Favorite Images">
        {newsletter.map((news: any, index: number) => {
          return (
            <SplideSlide key={index}>
              <CardThree
                imgOne={news.images[0]}
                imgTwo={news?.images[1]}
                title={news.title}
                description={`${news.description.slice(0, 150)}...`}
                id={news.id}
              />
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
};

export default Newsletter;
