import { Button, Card, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

export const SolutionCard = styled(Card)(() => ({
  //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  cursor: "pointer",
  maxWidth: "100%",
  width: 275,
  borderRadius: 15,
  "&:hover": { boxShadow: "0px 5px 7px 1px #3eb49c8a" },
  transition: "all 0.5s ease-in-out",
  paddingBottom: 20,
  paddingTop: 10,
}));

export const MainBtn = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#3EB49D",
  borderRadius: "100px",
  padding: "10px 30px",
  transition: "all 0.2s ease-in-out",
  "&:hover": { backgroundColor: "#16806a" },
}));

export const TitleBtn = styled(Typography)(() => ({
  letterSpacing: "0.1em",
  fontSize: 32,
  color: "#3EB49D",
  cursor: "pointer",
  flex: 1,
  "&:hover": { color: "#16806a" },
}));

export const MainLoadingBtn = styled(LoadingButton)(() => ({
  color: "white",
  backgroundColor: "#3EB49D",
  borderRadius: "100px",
  padding: "10px 30px",
  transition: "all 0.2s ease-in-out",
  width: "fit-content",
  maxWidth: "100%",
  "&:hover": { backgroundColor: "#16806a" },
  "&:disabled": {
    backgroundColor: "#3eb49c65",
    color: "white",
  },
  fontWeight: 700,
  letterSpacing: 2,
}));

export const SoonChip = styled(Chip)(() => ({
  marginLeft: 16,
  backgroundColor: "rgb(233, 95, 95)",
  color: "white",
  fontSize: 11,
}));
