// ** React imports
import React, { FC } from "react";

// ** MUI imports
import { Box, Typography, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// ** Icons imports
import SourceIcon from "@mui/icons-material/Source";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CategoryIcon from "@mui/icons-material/Category";

// ** Router imports
import { useNavigate, useParams } from "react-router-dom";

// ** Data imports
import { filterResearchsAndDevById } from "../../dummyData/researchsData";

const ResearchsAndDev = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const researchList = filterResearchsAndDevById(id);

  return (
    <Box sx={{ pb: 4, px: { md: 16, xs: 2 } }}>
      <div
        style={{
          color: "#55565b",
          letterSpacing: ".125rem",
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        <hr style={{ width: "10%", borderColor: "#3EB49D" }} />
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: "black",
            textTransform: "uppercase",
            my: 8,
          }}
        >
          {researchList[1]?.title}
        </Typography>
      </div>
      <Box
        sx={{
          border: {
            md: "2px solid #3EB49D",
            sm: "2px solid #3EB49D",
            xs: "none",
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { md: "10px", xs: 0 },
          position: "relative",
          borderRadius: "15px",
        }}
      >
        {researchList[0] !== null && (
          <Tooltip title="Previous Research and development">
            <IconButton
              onClick={() =>
                navigate(`/research-and-development/${researchList[0]}`)
              }
              sx={{
                backgroundColor: "white",
                cursor: "pointer",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "-15px",
                transition: "all 0.5s ease-in-out",
                boxShadow: "-3px 0px 0px 0px #899599",
                "&:hover": {
                  boxShadow: "5px 0px 0px -2px #3EB49D",
                  backgroundColor: "white",
                },
              }}
            >
              <NavigateBeforeIcon sx={{ color: "#3EB49D" }} />
            </IconButton>
          </Tooltip>
        )}

        {researchList[2] !== null && (
          <Tooltip title="Next Solution">
            <IconButton
              onClick={() =>
                navigate(`/research-and-development/${researchList[2]}`)
              }
              sx={{
                backgroundColor: "white",
                cursor: "pointer",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: "-20px",
                transition: "all 0.5s ease-in-out",

                boxShadow: "5px 0px 0px -1px #899599",
                "&:disabled": {
                  backgroundColor: "red",
                },
                "&:hover": {
                  boxShadow: "-3px 0px 0px 0px #3EB49D",
                  backgroundColor: "white",
                },
              }}
            >
              <NavigateNextIcon sx={{ color: "#3EB49D" }} />
            </IconButton>
          </Tooltip>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
            gap: 4,
            mt: 6,
            p: { md: 4, xs: 2 },
            flexWrap: "wrap",
          }}
        >
          <img
            style={{
              width: 500,
              borderRadius: 4,
              height: 300,
              minWidth: 200,
              maxHeight: "100%",
              maxWidth: "100%",
              margin: "auto",
              objectFit: "contain",
              display: "block",
            }}
            src={researchList[1].images[0]}
            alt=""
          />
          <Box sx={{ flex: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                lineHeight: 2,
                textAlign: "justify",
                lineBreak: "auto",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {researchList[1]?.description} <br />
            </Typography>

            <Box sx={{ marginTop: 5 }}>
              <ProjectDetails
                icon={<SourceIcon sx={{ fontSize: 26, color: "#3EB49D" }} />}
                title="Project Title"
                subtitle={researchList[1]?.title}
              />

              <ProjectDetails
                icon={<CategoryIcon sx={{ fontSize: 26, color: "#3EB49D" }} />}
                title="Category"
                subtitle={researchList[1]?.category}
              />
              <ProjectDetails
                icon={
                  <CalendarMonthIcon sx={{ fontSize: 26, color: "#3EB49D" }} />
                }
                title="Started on"
                subtitle={researchList[1]?.startedOn}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ProjectDetails: FC<any> = ({ icon, title, subtitle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 1,
        width: { md: 400, xs: "auto" },
        maxWidth: "100%",
      }}
    >
      <Box sx={{ flex: "10%" }}>{icon}</Box>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 700,
          color: "#55565b",
          flex: "20%",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          fontFamily: "Poppins, sans-serif",
          flex: "10%",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default ResearchsAndDev;
