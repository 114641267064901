import reducers from "../redux/reducers/index";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const initialState = {};

export const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch