import { Solutions } from "../../views/LandingPage/solutions";
import { HomeHero } from "../../components/header/header";
import Consulting from "../../views/LandingPage/consulting";
// import Clients from "../../views/LandingPage/client";
import ResearchProject from "../../views/LandingPage/researchs";
// import Community from "../../views/LandingPage/community";
import Careers from "../../views/LandingPage/careers";
// import OpenSource from "../../views/LandingPage/openSource";
import Contact from "../../views/LandingPage/contact";
import Footer from "../../components/footer/footer";
import Newsletter from "../../views/LandingPage/newsLetter";

function Landing() {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <HomeHero />
        <Solutions />
        <Consulting />
        {/* <Clients /> */}
        <ResearchProject />
        <Careers />
        {/* <Community /> */}
        <Newsletter />
        {/* <OpenSource /> */}
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Landing;
