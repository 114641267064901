import React from "react";
import Typography from "@mui/material/Typography";
import notFound from "../../assets/vector/7.svg";
import Box from "@mui/material/Box";
import { CardMedia } from "@mui/material";
import { MainBtn } from "../../components/StyledComponents";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: 250 }}>
      <Box
        sx={{
          background: "linear-gradient(45deg, white 50%, #EBFFFBBD 50%)",
          padding: "50px 60px 0px 60px",
          display: "flex",
          flexDirection: { md: "row", xs: "column-reverse" },
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            flex: 1,
            textAlign: { md: "left", xs: "center", color: "#3EB49D" },
          }}
        >
          <Typography variant="h1" sx={{ fontWeight: 800 }}>
            404
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 700, lineHeight: 2 }}>
            Page Not found!
          </Typography>
          <Typography
            variant="body2"
            sx={{ width: { md: "80%", xs: "100%", color: "black" } }}
          >
            Please check your URL or return to VIVIA home.
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              marginTop: 20,
            }}
          >
            <LocalPhoneOutlinedIcon />
            <Typography
              variant="body2"
              sx={{ color: "#555A69", fontWeight: 600 }}
            >
              +216 73 11 11 96
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              marginTop: 20,
            }}
          >
            <EmailOutlinedIcon />
            <Typography
              variant="body2"
              sx={{ color: "#555A69", fontWeight: 600 }}
            >
              contact@vivianet.com
            </Typography>
          </div>
          <MainBtn disableRipple onClick={() => navigate("/")} sx={{ mt: 5 }}>
            Home
          </MainBtn>
        </Box>
        <CardMedia
          component="img"
          image={notFound}
          alt=""
          sx={{ flex: 1, width: { md: "50%", xs: "100%" } }}
        />
      </Box>
    </div>
  );
};

export default NotFound;
