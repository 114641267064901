import doctor from "../assets/images/doctor-touching2.jpg";

export interface IResearchsAndDevProps {
  title: string;
  icon: any;
  category: string;
  images?: [string];
  startedOn: string;
  description: string;
  id: string;
}

const imda: IResearchsAndDevProps = {
  title: "IMDA",
  icon: doctor,
  category: "eHealth",
  startedOn: "2022",
  id: "1",
  images: [doctor],
  description:
    "Introducing IMDA (Intelligent Medical Document Analysis), an innovative solution that automates the structuring and semantic analysis of medical documents, specifically SMPCs (Summary of Product Characteristics). Developed in MVP mode, IMDA recently underwent a successful demonstration with the European Medical Agency (EMA) in October 2022. We are excited to announce that IMDA is set to be released in July 2023, bringing transformative advancements to medical document analysis and empowering healthcare professionals with improved efficiency and decision-making capabilities.",
};

const researchsAndDev: IResearchsAndDevProps[] = [imda];

export function filterResearchsAndDevByCategory(
  category: string
): IResearchsAndDevProps[] {
  return researchsAndDev.filter((research) => research.category === category);
}

export function filterResearchsAndDevById(id?: string): any {
  const research = researchsAndDev.find((resear) => resear.id === id);
  const index = id ? researchsAndDev.findIndex((s) => s.id === id) : -1;
  const before = index > 0 ? researchsAndDev[index - 1].id : null;
  const after =
    index < researchsAndDev.length - 1 ? researchsAndDev[index + 1].id : null;

  return [before, research, after];
}
export default researchsAndDev;
