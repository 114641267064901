import { useRoutes } from "react-router-dom";
// layouts
//
import { SimpleLayout } from "./layouts/layouts";
import Solution from "./pages/Solution/solution";
import Landing from "./pages/Home/home";
import SolutionsThree from "./pages/Solutions/solutions";
import ComingSoon from "./pages/CommingSoon/comingSoon";
import NoBannerLayout from "./layouts/NoBannerLayout";
import NotFound from "./pages/NotFound/NotFound";
import Team from "./pages/Team/Team";
import Newsletters from "./pages/Newsletters/newsletters";
import Newsletter from "./pages/Newsletter/newsletter";
import ResearchsAndDev from "./pages/ResearchsAndDev/researchsAndDev";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Landing />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "/our-solutions", element: <SolutionsThree /> },
        { path: "/our-solutions/solution/:id", element: <Solution /> },
        { path: "/team", element: <Team /> },
        { path: "/newsletter", element: <Newsletters /> },
        { path: "/newsletter/:id", element: <Newsletter /> },
        { path: "/research-and-development/:id", element: <ResearchsAndDev /> },
      ],
    },
    {
      element: <NoBannerLayout />,
      children: [{ path: "/consulting", element: <ComingSoon /> }],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return routes;
}
