import { Box } from "@mui/material";
import { CardTwo } from "../../components/customCards/customCards";
import { HomeTitle } from "./title";
import researchsAndDev from "../../dummyData/researchsData";

const ResearchProject = () => {
  return (
    <>
      {researchsAndDev.length > 0 && (
        <Box sx={{ py: 4, px: { md: 16, xs: 2 } }}>
          <HomeTitle title="RESEARCH & DEVELOPMENT" />
          <Box
            sx={{
              marginBottom: "100px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 3,
              justifyContent: "center",
              m: "auto",
            }}
          >
            {researchsAndDev?.map((research) => {
              return <CardTwo key={research.id} research={research} />;
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ResearchProject;
