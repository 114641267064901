// ** React import
import React, { useState } from "react";

// ** Router import
import { useNavigate } from "react-router-dom";

// ** MUI import
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";

// ** Dummy data imports
import newsletter, {
  filterNewsletterByAllCategories,
} from "../../dummyData/newlsetterData";

// Icons import
import SearchIcon from "@mui/icons-material/Search";

// Images import
import contact from "../../assets/images/contact.jpg";

// ** CSS import
import "./styles.css";

const Newsletters = () => {
  // ** States
  const [word, setWord] = useState("");

  // ** Router
  const navigate = useNavigate();
  //   const categCount = getCategoryCounts();
  const categoriesNumber = filterNewsletterByAllCategories([
    "Industry",
    "eHealth",
  ]);
  const filteredData = newsletter?.filter((f: any) => {
    return word === "" || f.title.toLowerCase().includes(word.toLowerCase());
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: 2,
        py: 4,
        // px: { md: 16, xs: 2 },
        px: "5%",
      }}
    >
      <Box sx={{ flex: 2, display: "grid", gap: 2 }}>
        {filteredData.map((news: any) => {
          return (
            <Card
              key={news.id}
              sx={{
                maxWidth: "100%",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                gap: 2,
                alignItems: "center",
                p: 2,
                cursor: "pointer",
                transition: "all 0.5s ease-in-out",
                "&:hover": {
                  transition: "all 0.2s ease-out",
                  boxShadow: " 0px 4px 8px rgba(38, 38, 38, 0.2)",
                  top: "-4px",
                  backgroundColor: "white",
                },
              }}
            >
              <CardMedia
                component="img"
                image={news.images[0]}
                alt={news.title}
                sx={{
                  width: { md: "30%", xs: "100%" },
                  height: 200,
                  objectFit: "contain",
                }}
              />
              <div>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe">
                      {news.author.slice(0, 1)}
                    </Avatar>
                  }
                  title={news.author}
                  subheader={news.createdAt}
                />
                <CardContent>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ mb: 2, "&:hover": { fontWeight: 700 } }}
                    onClick={() => {
                      navigate(`/newsletter/${news.id}`);
                    }}
                  >
                    {news.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {news.description.slice(0, 150)}...
                  </Typography>
                  <Button
                    variant="outlined"
                    disableRipple
                    size="small"
                    sx={{ mt: 2 }}
                    onClick={() => {
                      navigate(`/newsletter/${news.id}`);
                    }}
                  >
                    Read more
                  </Button>
                </CardContent>
              </div>
            </Card>
          );
        })}
      </Box>
      <Card
        sx={{
          flex: 1,
          p: 2,
          display: "flex",
          gap: 8,
          flexDirection: "column",
        }}
      >
        <div>
          <h3 className="sidebar-title">Search</h3>
          <Box
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              border: "#ebebeb solid 1px",
            }}
          >
            <InputBase
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setWord(event.target.value);
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here ..."
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Box>
        </div>
        <div>
          <h3 className="sidebar-title">Categories</h3>
          <ul className="blog-sidebar-catgory-list">
            {categoriesNumber.map((category, index) => {
              return (
                <li key={index}>
                  {category.categoryName} ({category.newsletterNumber})
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h3 className="sidebar-title">Contact us</h3>
          <div
            style={{
              position: "relative",
              width: "fit-content",
              margin: "auto",
            }}
          >
            <img
              className="side-bar-contact-img"
              src={contact}
              alt="contact us vivia"
            />
            <Button
              color="primary"
              variant="contained"
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
                color: "white",
              }}
              className="side-bar-contact-btn"
            >
              Contact us
            </Button>
          </div>
        </div>
      </Card>
    </Box>
  );
};

export default Newsletters;
