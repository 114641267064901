import axios from "axios";
import { Dispatch } from "redux";
import {
  SEND_MSG,
  SEND_MSG_FAILED,
  LOAD_MSG,
} from "../actionTypes/researchTypes";

export const sendMsg_ = (data: any) => async (dispatch: Dispatch<any>) => {
  try {
    const result = await axios.post("/vivia-v1/messages/create-message", data);
    console.log(result);
    dispatch({
      type: SEND_MSG,
      payload: result.data,
    });
  } catch (err: any) {
    dispatch({
      type: SEND_MSG_FAILED,
      payload: err.response.data,
    });
  }
};

export const sendMsg = (data: any) => async (dispatch: any) => {
  try {
    dispatch({ type: LOAD_MSG });

    const result = await axios.post("/vivia-v1/messages/create-message", data);
    dispatch({ type: SEND_MSG, payload: result.data });

    return result;
  } catch (error: any) {
    dispatch({ type: SEND_MSG_FAILED, payload: error.response.data });
  }
};
