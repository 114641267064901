import React, { FC } from "react";
import {
  Card,
  Box,
  CardMedia,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";

import { MainBtn, SolutionCard } from "../StyledComponents";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const CardTwo: FC<any> = ({ research }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        borderRadius: 6,
        height: { md: 500, xs: "100%" },
        width: { md: "350px", xs: "100%" },
        maxWidth: "100%",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          transform: "translate(0rem, -2ex)",
          transition: "all 0.5s",
          boxShadow: "0px 5px 7px 1px #3eb49c8a",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "space-between",
        cursor: "pointer",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: { md: "fit-content", xs: "80%" },
            borderRadius: 4,
            height: 220,
            maxHeight: "100%",
            backgroundSize: "cover",
          }}
          image={research.icon}
          alt={research.title}
        />
        <Box sx={{ borderTop: "2px solid #3EB49D", width: "30%" }} />
        <Typography
          variant="h4"
          sx={{
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: 18,
            textAlign: "center",
          }}
        >
          {research.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#605d5d",
            lineHeight: 2,
            textAlign: { md: "left", xs: "center" },
          }}
        >
          {research.description.slice(0, 150)} ...
        </Typography>
        <MainBtn
          disableRipple
          variant="contained"
          onClick={() => navigate(`/research-and-development/${research.id}`)}
        >
          Discover more
        </MainBtn>
      </CardContent>
    </Card>
  );
};

export const CardThree: FC<any> = ({
  imgOne,
  imgTwo,
  title,
  description,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
      <Grid
        container
        sx={{
          border: "2px solid #F5F5F5",
          p: 2,
          transition: "all 0.5s ease-in-out",
          borderRadius: 4,
          "&:hover": {
            boxShadow: "0px 5px 7px 1px #3eb49c8a",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <CardMedia
            component="img"
            sx={{ flex: 1, width: { md: "100%", sm: "60%", xs: "100%" } }}
            image={imgOne}
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={8} sx={{ pl: { md: 2, sm: 0 } }}>
          <h1>{title}</h1>
          <Typography
            variant="body2"
            sx={{
              width: "100%",
              mt: "40px",
              color: "#A7A7A7",
              lineHeight: 1.5,
              textAlign: "justify",
              fontSize: 18,
            }}
          >
            {description}
          </Typography>
          <MainBtn
            sx={{ float: "right", mt: 2 }}
            onClick={() => navigate(`/newsletter/${id}`)}
          >
            Discover more
          </MainBtn>
        </Grid>
      </Grid>
      <Card
        sx={{
          width: "fit-content",
          maxWidth: "100%",
          border: "2px solid #F5F5F5",
          transition: "all 0.5s ease-in-out",
          borderRadius: 4,
          display: { lg: "flex", md: "none", xs: "none" },
          placeContent: "center",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            boxShadow: "0px 5px 7px 1px #3eb49c8a",
          },
          p: 2,
        }}
      >
        <CardMedia
          component="img"
          sx={{
            flex: 1,
            width: "90%",
            backgroundSize: "cover",
            display: "block",
          }}
          image={imgTwo}
          alt=""
        />
      </Card>
    </Box>
  );
};

export const CardOne: FC<any> = ({ title, description, icon, link }) => {
  const navigate = useNavigate();
  return (
    <SolutionCard className="myDiv" onClick={() => navigate(link)}>
      <CardContent style={{ textAlign: "center" }}>
        <img src={icon} style={{ width: "50px", height: "50px" }} alt="" />
        <h3>{title} </h3>
        <h5 style={{ fontWeight: 400 }}>{description}</h5>
      </CardContent>
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            fontWeight: "bold",
            cursor: "pointer",
            transition: "all 0.5s ease-in-out",
            letterSpacing: "0.2em",
          }}
          className="details-icon"
        >
          See details
          <div>
            <ChevronRightIcon />
          </div>
        </div>
      </Box>
    </SolutionCard>
  );
};
