import { combineReducers } from "redux";
import researchReducer from "./research";
import productReducer from "./solutions";
import contactReducer from "./contact";

// @ts-ignore

const reducers = combineReducers({
  Product: productReducer,
  Research: researchReducer,
  Contact: contactReducer,
});
export default combineReducers({
  reducers,
});
