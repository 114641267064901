// ** React imports
import React, { FC } from "react";

// ** Images imports
import icon from "../../assets/icons/Diamond.svg";

export const HomeTitle: FC<any> = ({ title, white }) => {
  return (
    <div>
      <hr style={{ borderColor: "#3EB49D" }} />
      <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
        <img src={icon} alt="" />
        <h2
          style={{
            fontSize: 24,
            letterSpacing: 2,
            lineHeight: 1,
            color: white ? "white" : "",
            textTransform: "uppercase",
          }}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};
