import React, { useState, useEffect, FC } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import img from "../../assets/images/img69.png";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import GroupIcon from "@mui/icons-material/Group";
import { HomeTitle } from "./title";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

const Consulting = () => {
  const [screenWidth, setScreenWidth] = useState<any>(window.innerWidth);

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  return (
    <Box sx={{ py: 4, px: { md: 16, xs: 2 } }}>
      <HomeTitle title="NEED CONSULTING ?" />

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          p: 2,
          columnGap: 4,
          rowGap: 2,
        }}
      >
        {/* LEFT */}
        <Box
          sx={{
            flex: 1,
            display: "grid",
            placeItems: "center",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: { md: "100%", xs: "100%" },
              height: { md: "auto", xs: 195 },
            }}
            image={img}
            alt="Paella dish"
          />
        </Box>
        {/* RIGHT */}
        <Box sx={{ flex: 2 }}>
          <Typography
            variant="h4"
            sx={{ color: "#142352", fontWeight: 500, mb: 2 }}
          >
            A group of experienced consultants will provide you with expert
            guidance and support
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "justify", color: "#A7A7A7", mb: 3 }}
          >
            Our consulting services cover a wide range of areas, including IT
            strategy development, system integration, data analytics, and much
            more. We leverage the latest technologies and industry best
            practices to deliver innovative solutions that optimize your
            operations, streamline processes, and improve overall efficiency.
          </Typography>

          <Grid container spacing={2}>
            <ConsultingCard
              icon={
                <GroupIcon
                  style={{
                    color: "#3EB49D",
                    fontSize: "50px",
                    position: "relative",
                    top: "25%",
                  }}
                />
              }
              title="2 Clients"
              description="Our clients trust us to deliver innovative IT solutions that drive their business forward and provide a competitive edge."
            />
            <ConsultingCard
              icon={
                <AccessTimeIcon
                  style={{
                    color: "#3EB49D",
                    fontSize: "50px",
                    position: "relative",
                    top: "25%",
                  }}
                />
              }
              title="25 Years"
              description="Our team has been helping businesses succeed in the digital landscape for [X] years and counting."
            />
            <ConsultingCard
              icon={
                <EmojiEventsIcon
                  style={{
                    color: "#3EB49D",
                    fontSize: "50px",
                    position: "relative",
                    top: "25%",
                  }}
                />
              }
              title="1 Award"
              description="Our commitment to delivering exceptional solutions has earned us a reputation for excellence and garnered industry recognition."
            />
            <ConsultingCard
              icon={
                <FolderCopyIcon
                  style={{
                    color: "#3EB49D",
                    fontSize: "50px",
                    position: "relative",
                    top: "25%",
                  }}
                />
              }
              title="80 PROJECTS"
              description="Our project portfolio showcases our ability to tackle complex challenges and deliver impactful results."
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const ConsultingCard: FC<any> = ({ icon, title, description }) => {
  return (
    <Grid item xs={12} md={6} lg={6} sx={{ cursor: "pointer" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          p: 2,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0.125rem 0.1875rem 0.9375rem rgba(0, 0, 0, .15)",
            // bgcolor: "#3EB49D",
          },
        }}
      >
        <Box>{icon}</Box>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: 2,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#555A69",
              fontWeight: "normal",
              marginTop: 0,
              width: { md: "80%", xs: "100%" },
              fontSize: 14,
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Consulting;
