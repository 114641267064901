// ** MUI imports
import { Box, Typography } from "@mui/material";

// ** Redux imports

// ** Styled components imports
import { TitleBtn } from "../../components/StyledComponents";

// ** Router imports
import { useNavigate } from "react-router-dom";
import { CardOne } from "../../components/customCards/customCards";

import { HomeTitle } from "./title";
// ** Functions imports
import { filterDummyDataByCategories } from "../../dummyData/solutionsData";

export const Solutions = () => {
  // ** Routes
  const navigate = useNavigate();

  const solutionsByCategory = filterDummyDataByCategories();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        py: 4,
        px: { md: 16, xs: 2 },
      }}
    >
      <HomeTitle title="OUR SOLUTIONS" />

      <Box sx={{ width: "100%", m: "auto" }}>
        {solutionsByCategory?.map((category: any) => {
          return (
            <>
              <TitleBtn
                sx={{
                  width: "fit-content",
                  mb: 4,
                }}
                onClick={() => navigate(`/our-solutions#${category.category}`)}
              >
                {category.category === "industry" ? "Industry" : "eHealth"}
              </TitleBtn>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  width: "fit-content",
                  m: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 4,
                    justifyContent: "center",
                    m: "auto",
                  }}
                >
                  {category.solutionsList.map((item: any, index: number) => {
                    return (
                      <CardOne
                        title={item.title}
                        description={`${item.description.slice(0, 90)}...`}
                        icon={item.icon}
                        link={`our-solutions/solution/${item.id}`}
                      />
                    );
                  })}
                </Box>
                <Typography
                  sx={{
                    alignSelf: "end",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "end",
                    fontSize: { md: 14, xs: 10 },

                    fontWeight: "bold",
                    cursor: "pointer",
                    width: 275,
                    maxWidth: "100%",
                    float: "right",
                    color: "#16806a",
                  }}
                  onClick={() =>
                    navigate(`/our-solutions#${category.category}`)
                  }
                >
                  View all solutions ...
                </Typography>
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  );
};
