// ** React imports
import React from "react";

// ** Router imports
import { Link } from "react-router-dom";

// ** MUI imports
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Images import
import team1 from "../../assets/team/lotfiammar.png";
import team2 from "../../assets/team/lotfibenromdhane.png";
import team3 from "../../assets/team/souad.png";
import team4 from "../../assets/team/wissal.png";
import devTeam1 from "../../assets/team/roua.jpeg";
import devTeam2 from "../../assets/team/anouar.jpeg";
import devTeam3 from "../../assets/team/donia.jpeg";
import devTeam4 from "../../assets/team/marwajedidi.jpeg";
import devTeam5 from "../../assets/team/majd.jpeg";
import devTeam6 from "../../assets/team/marwabrahmi.jpeg";

// ** Custom components
import { HomeTitle } from "../../views/LandingPage/title";
import Phone from "./Phone";

// ** Import styles
import "./styles.css";

// ** Icon imports
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Team = () => {
  return (
    <Box>
      <Box sx={{ px: { md: 16, xs: 2 } }}>
        <HomeTitle title="OUR TEAM" />
      </Box>
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="h3"
          sx={{ lineHeight: 2, fontWeight: 700, letterSpacing: 2 }}
        >
          The CO-Founders
        </Typography>
        <Typography
          sx={{ width: { md: "50%", xs: "80%" }, m: "auto", lineHeight: 2 }}
        >
          Far far away, behind the word mountains, far from the countries
          Vokalia and Consonantia, there live the blind texts. Separated they
          live in Bookmarksgrove.
        </Typography>
      </div>
      <div
        style={{
          marginTop: 64,
          display: "flex",
          gridAutoFlow: "row",
          placeContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {coFounders?.map((team: any) => {
          return (
            <figure className="cofounder-figure">
              <img src={team.img} alt={team.title} />
              <figcaption>
                {team.title}
                <p>{team.description}</p>
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                  <Link
                    style={{ color: "white" }}
                    to={team.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon
                      sx={{
                        bgcolor: "#ffffff28",
                        borderRadius: 2,
                        p: 1,
                        mr: 1,
                        pointer: "cursor",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#3eb49d",
                          transition: "all 0.5 ease-in-out",
                        },
                      }}
                    />
                  </Link>
                  <Phone />
                </div>
              </figcaption>
            </figure>
          );
        })}
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: 100,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="h3"
          sx={{ lineHeight: 2, fontWeight: 700, letterSpacing: 2 }}
        >
          The Team
        </Typography>
        <Typography
          sx={{ width: { md: "50%", xs: "80%" }, m: "auto", lineHeight: 2 }}
        >
          Far far away, behind the word mountains, far from the countries
          Vokalia and Consonantia, there live the blind texts. Separated they
          live in Bookmarksgrove.
        </Typography>
      </div>
      <Box
        sx={{
          m: "60px auto auto auto",
          display: "flex",
          gridAutoFlow: "row",
          placeContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          px: { md: 16, xs: 2 },
          transition: "all 0.5 ease-in-out",
          // width: { md: "70%", xs: "100%" },
          maxWidth: "100%",
        }}
      >
        {devTeam.map((item, index) => (
          <ImageListItem key={index} className="img-box header-overlay">
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{ borderRadius: 10 }}
            />
            <ImageListItemBar
              className="image-description"
              title={item.title}
              subtitle={item.description}
              actionIcon={
                <Link
                  style={{ color: "white" }}
                  to={item.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon
                    sx={{
                      bgcolor: "#ffffff28",
                      borderRadius: 2,
                      p: 1,
                      mr: 1,
                      pointer: "cursor",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#3eb49d",
                        transition: "all 0.5 ease-in-out",
                      },
                    }}
                  />
                </Link>
              }
            />
          </ImageListItem>
        ))}
      </Box>
    </Box>
  );
};

const coFounders = [
  {
    img: team1,
    title: "Lotfi Ammar",
    description:
      "Over 20 years extensive experience in the area of regulatory and clinical operations, digital transformation and program management.",
    linkedIn: "https://www.linkedin.com/in/lotfi/",
  },

  {
    img: team3,
    title: "Souad Dziri",
    description:
      "Over 10 years experience in r&d in health sector pharm d, msc in management eshmoun ceo & co-founder entrepreneur.",
    linkedIn: "https://www.linkedin.com/in/souad-dziri-b8ab8a28/",
  },
  {
    img: team4,
    title: "Wissal Farjallah",
    description: "Telecommunication and computer science engineer.",
    linkedIn: "https://www.linkedin.com/in/wissal-farjallah-360b69197/",
  },
  {
    img: team2,
    title: "Lotfi Ben Romdhane",
    description:
      "Professor in computer science and ai specialist with more than 20 years of experience.",
    linkedIn: "https://www.linkedin.com/in/lotfi-ben-romdhane-81b14913/",
  },
];

const devTeam = [
  {
    img: devTeam1,
    title: "Roua Zayeni",
    description: "FullStack Developer",
    linkedIn: "https://www.linkedin.com/in/zayaniroua/",
  },
  {
    img: devTeam2,
    title: "Anouar Dhahri",
    description: "FullStack Developer",
    linkedIn: "https://www.linkedin.com/in/anouar-dhahri-025698219/",
  },
  {
    img: devTeam3,
    title: "Donia Ben Romdhane",
    description: "FullStack Developer",
    linkedIn: "https://www.linkedin.com/in/donia-b-romdhane-9b9a6514a/",
  },
  {
    img: devTeam4,
    title: "Marwa Jedidi",
    description: "Business Developer",
    linkedIn: "https://www.linkedin.com/in/marwa-jedidi-a04147181/",
  },
  {
    img: devTeam5,
    title: "Majd Eddin Fazaa",
    description: "Flutter Developer",
    linkedIn: "https://www.linkedin.com/in/el-fazaa-majd-eddine-6b2439165/",
  },

  {
    img: devTeam6,
    title: "Marwa Brahmi",
    description: "FullStack Developer",
    linkedIn: "https://www.linkedin.com/in/marwa-brahim/",
  },
];
export default Team;
