import {
  GET_PRODUCTS,
  GET_PRODUCT,
  PRODUCT_LOADING,
  PRODUCT_FAILED,
} from "../actionTypes/productTypes";

//interface Products Initial State
interface IProductsInState {
  products: [];
  product: {};
  loading: boolean;
  error: string | null;
}

const initialState: IProductsInState = {
  products: [],
  product: {},
  loading: false,
  error: null,
};

interface SolutionsAction {
  type: string;
  payload: any;
}
//Product reducer
export default function productReducer(
  state = initialState,
  action: SolutionsAction
): IProductsInState {
  const { type, payload } = action;
  switch (type) {
    //Initial State
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    //There is an ERROR
    case PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.msg,
        products: [],
      };
    //Get all Products
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload.products,
        error: null,
        loading: false,
      };
    //Get Product By ID
    case GET_PRODUCT:
      return {
        ...state,
        product: payload.product,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
}
